import React from 'react'
import Particles from 'react-particles-js';
import {Animated} from "react-animated-css";
// shape
import snowflake from '../../images/hero/snowflake.svg';
import snowflake2 from '../../images/hero/snowflake2.svg';
import snowflake3 from '../../images/hero/snowflake3.svg';
import snowflake4 from '../../images/hero/snowflake4.svg';
import heart from '../../images/hero/heart.svg';
import pikachu from '../../images/hero/pikachu.svg';
import { texts } from '../../texts';

import './style.css'


const PreviewHero = (props) => {
    return (
        <div className={`previewHeroArea item1 ${props.preview}`} id="home">
            <div className="container">
                  <div className="slide-content">
                      <div className="slide-subtitle">
                          <h4>{texts.miscellaneous.weereGettingMarried}</h4>
                      </div>
                      <div className="slide-title">
                          <h2>{texts.engagedCouple.names}</h2>
                      </div>
                      <div className="slide-text">
                          <p>{texts.celebration.dateSmall}</p>
                      </div>
                      <Animated>
                          <div className="animated-circle"></div>
                      </Animated>
                  </div>
              </div>


            <Particles
                className="particaleWrapper"
                params={{
                    particles: {
                        number: {
                            value: 20,
                            density: {
                                enable: true,
                                value_area: 800
                            }
                        },
                        line_linked: {
                            enable: false
                        },
                        move: {
                            speed: 1.5,
                            out_mode: "in"
                        },
                        shape: {
                            type: [
                                "images",
                            ],
                            images: [
                                {
                                    src: `${snowflake}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake2}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${heart}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake3}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake4}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake2}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${pikachu}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake3}`,
                                    height: 20,
                                    width: 20,
                                },
                                {
                                    src: `${snowflake4}`,
                                    height: 20,
                                    width: 20,
                                }
                            ]
                        },
                        color: {
                            value: "#85aaba"
                        },
                        size: {
                            value: 20,
                            random: true,
                            anim: {
                                enable: true,
                                speed: 2,
                                size_min: 10,
                                sync: true
                            }
                        }

                    },
                    opacity: {
                        value: 0.4008530152163807,
                        random: false,
                        anim: {
                            enable: false,
                            speed: 1,
                            opacity_min: 0.1,
                            sync: false
                        }
                    },
                    interactivity: {
                        detect_on: "window",
                        events: {
                            onhover: {
                                enable: true,
                                mode: "repulse"
                            },
                            onclick: {
                                enable: false,
                                mode: "bubble"
                            },
                            resize: true
                        },
                        modes: {
                            grab: {
                                distance: 400,
                                line_linked: {
                                    opacity: 1
                                }
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 8,
                                speed: 3
                            },
                            repulse: {
                                distance: 100,
                                duration: 0.4
                            },
                            push: {
                                particles_nb: 4
                            },
                            remove: {
                                particles_nb: 2
                            }
                        }
                    },
                    retina_detect: false
                }}
            />
        </div>
    )
}
export default PreviewHero