
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Sectiontitle from '../section-title'
import { texts } from '../../texts';
import strory1 from '../../images/events/ceremony.jpg'
import strory2 from '../../images/events/party.jpg'

import './style.css'

const Location = (props) => {

    const {
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [locationUrl, setLocationUrl] = useState('');

    const toggle = (newLocationUrl) => () => {
        setLocationUrl(newLocationUrl);
        setModal(!modal)
    };

    return (
        <div id="event" className="event-section section-padding">
            <Sectiontitle section={texts.whenNWhere.title} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory1} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>{texts.whenNWhere.ceremony.title}</h3>
                                                    <span>{texts.whenNWhere.ceremony.date}</span>
                                                    <span>{texts.whenNWhere.ceremony.location}</span>
                                                    <Button className="btn locationBtn" onClick={toggle(texts.whenNWhere.ceremony.locationUrl)}>Location</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>{texts.whenNWhere.party.title}</h3>
                                                    <span>{texts.whenNWhere.party.date}</span>
                                                    <span>{texts.whenNWhere.party.location}</span>
                                                    <Button className="btn locationBtn" onClick={toggle(texts.whenNWhere.party.locationUrl)}>Location</Button>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory2} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal isOpen={modal} toggle={toggle("")} className={className}>
                                        <ModalHeader toggle={toggle("")}>{texts.whenNWhere.locationText}</ModalHeader>
                                        <ModalBody>
                                            <div className="location-map">
                                                <iframe src={locationUrl} />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;