import React from  'react';

// components
import Couple from '../../components/couple';
import PreviewHero from '../../components/hero2';
// import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import Location from '../../components/location';
import Rsvp from '../../components/rsvp';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Footer from '../../components/footer'
import couple1 from '../../images/couple/nuriChicago.jpg'
import couple2 from '../../images/couple/hector_chicago.jpg'


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <PreviewHero/>
           <Saveday saveday={'s2'}/>
           <Couple couple={'s2'} coupleimg1={couple1} coupleimg2={couple2}/>
           <Welcome welcome={'s2'}/>
           {/**<Story/>*/}
           {/**<People/>*/}
           <Location/>
           {/**<Gallery/>*/}
           <Rsvp/>
           {/**<Getting/>*/}
           {/**<Gift/>*/}
           <Footer />
       </div>
    )
}

export default Homepage;