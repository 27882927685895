
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { texts } from '../../texts';
import './style.css'

const Welcome = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (

    <div  className={`welcome-area ${props.welcome}`}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="welcome-content">
                        <h2>{texts.welcome.title}</h2>
                        <p>{texts.welcome.desc}</p>
                        <div className="btn"><AnchorLink href='#rsvp'>{texts.welcome.rvspButton}</AnchorLink></div>
                        <Button className="btn" onClick={toggle}>{texts.welcome.locationText}</Button>
                        <Modal isOpen={modal} toggle={toggle} className={className}>
                            <ModalHeader toggle={toggle}>{texts.welcome.locationText}</ModalHeader>
                            <ModalBody>
                                <div className="location-map">                                  
                                    <iframe src={texts.welcome.locationUrl} />
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
}

export default Welcome;