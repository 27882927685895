import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import 'react-sticky-header/styles.css';
import MobileMenu from '../../components/MobileMenu'
import { texts } from '../../texts';



import './style.css'

const Header = () => {

    return(

          <div className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="logo">
                                <h2><Link to='/'>{texts.engagedCouple.names}</Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                    <li><AnchorLink href='#couple'>{texts.menu.couple}</AnchorLink></li>
                                    {/**<li><AnchorLink href='#story'>{texts.menu.story}</AnchorLink></li>*/}
                                    <li><AnchorLink href='#event'>{texts.menu.events}</AnchorLink></li>
                                    {/*<li><AnchorLink href='#gallery'>{texts.menu.gallery}</AnchorLink></li>*/}
                                    <li><AnchorLink href='#rsvp'>{texts.menu.rvsp}</AnchorLink></li>
                                    {/*<li><Link to='#'>{texts.menu.couple}</Link>
                                        <ul className="submenu">
                                            <li><Link to='/blog'>{texts.menu.blog}</Link></li>
                                            <li><Link to='/Blog-details'>{texts.menu.blogDetails}</Link></li>
                                        </ul>
                                    </li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
             </div>
          </div>
        
    )
}

export default Header;
