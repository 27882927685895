import React, { Component } from 'react';
import FormfacadeEmbed from "@formfacade/embed-react";
import Sectiontitle from '../section-title'
import { texts } from '../../texts';
import './style.css'

class Rsvp extends Component {
    state = {
        isFormSubmitted: false,
    }

    onSubmitRvsp = () => {
        this.setState({ isFormSubmitted: true });
    }
    
    render() {
        const { isFormSubmitted } = this.state;

        if (isFormSubmitted) {
            return (
                <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                    <Sectiontitle section={texts.rvsp.title} />
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                                <div className="row">
                                    <div className="heading col-md-12 col-sm-6">
                                        <h4>{texts.rvsp.confirmTitle}</h4>
                                        <div className="gta-content">
                                            <p>{texts.rvsp.confirmDesc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                <Sectiontitle section={texts.rvsp.title} />
                <FormfacadeEmbed
                    formFacadeURL="https://formfacade.com/include/112191027356275855335/form/1FAIpQLScn1tbNycPrsfDL5BzZv_5blDaS9gqG5yq4pjpmU0yHOspSrw/classic.js/?div=ff-compose"
                    onSubmitForm={this.onSubmitRvsp}
                />
            </div>
        )
    }
}

export default Rsvp;