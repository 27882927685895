export const texts = {
  engagedCouple: {
    names: "Nuria Y Héctor"
  },
  menu: {
    couple: "Nosotros",
    story: "Historia",
    events: "Eventos",
    rvsp: "Confirma tu asistencia",
    gallery: "Galería",
    blog: "Blog",
    blogDetails: "Detalles"
  },
  couple: {
    title: "Nosotros",
    bride: {
      name: "Nuria García Magro",
      desc: "Soy Nuria García Magro, una bióloga salmantina apasionada de la neurociencia y profesora de Anatomía y Fisiolofía en la UFV. Siempre que escucho una salsa o una bachata mi cuerpo se pone en movimiento instintivamente."
    },
    groom: {
      name: "Héctor Casado Remesal",
      desc: "Soy Héctor Casado Remesal, un informático zamorano apasionado de los videojuegos y de Pokémon. No hay salsa de baile en Madrid en la que no haya estado, amo bailar."
    }
  },
  welcome: {
    title: "Bienvenidos a nuestro gran día",
    desc: "Nunca tuvimos claro si algún día nos casaríamos, lo que teníamos claro es que si ese día llegara, tendría que ser en la Capilla de Nuestra Señora de la Soledad de la Catedral de Salamanca. Y bueno... ese día llegó y queremos que estés con nosotros en este gran día",
    rvspButton: "Confirma tu asistencia",
    locationText: "Localización",
    locationUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d633.3960958245283!2d-5.666659866784067!3d40.960530131985415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f266aa3658407%3A0xaf9de7b5c128c5d7!2sCatedral%20de%20Salamanca!5e0!3m2!1ses!2ses!4v1722579357730!5m2!1ses!2ses",
  },
  loveStory: {
    title: "Nuestra historia de amor",
    weMet: {
      img: "",
      title: "Cuando nos conocimos",
      date: "24 Septiembre 2011",
      desc: "Fue una día mágico, nos conocimos en la sala \"Posada de las Ánimas\" en una fiesta de salsa, ese día bailamos la primera cancion de muchas: \"Lágrimas\" del grupo Aventura. Ese día algo se encendió en nuestros corazones"
    },
    weDate: {
      img: "",
      title: "Primera cita",
      date: "15 Noviembre 2012",
      desc: "Un día señalado en el calendario, eran las fiestas de San Alberto de Informática y Biología, Héctor estaba en Madrid haciendo la entrevista para entrar en Telefónica (y le cogieron), pero solo pensaba en volver a Salamanca para estar con Nuri. Llegó a media tarde, y entre mariposas y zorros, se encendió la llama del amor que hoy arde con mayor intensidad que nunca."
    },
    proposal: {
      img: "",
      title: "Proposición de matrimonio",
      date: "17 Julio 2024",
      desc: "O mejor debería decir... acuerdo de boda. Todo pasó cenando en la terraza de nuestra casa. Un miercoles de verano en el que empezamos a hablar de formalizar nuestra relación, y una cosa llevó a la otra y acabamos preguntando por la fecha de nuestra boda al hermano mayor de la cofradía de la soledad a las 23:30, y con posterior llamada a nuestros padres y hermanos para contarles la noticia."
    },
    engagement: {
      img: "",
      title: "Nuestro compromiso",
      date: "20 Julio 2024",
      desc: "Como todo lo que pasa en nuestras vidas, un impulso nos movió y acabamos formalizando nuestro compromiso a los 3 días. Acabamos tomando un café con el parroco Don Miguel, el Sábado por la tarde para formalizar el compromiso. Una locura!!!"
    }
  },
  whenNWhere: {
    title: "Cuando y donde",
    ceremony: {
      title: "Ceremonia Religiosa",
      date: "Sábado 7 de Diciembre de 2024, 13:00",
      location: "Categral de Salamanca (Capilla de Nuestra Señora de la Soledad)",
      locationUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d633.3960958245283!2d-5.666659866784067!3d40.960530131985415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f266aa3658407%3A0xaf9de7b5c128c5d7!2sCatedral%20de%20Salamanca!5e0!3m2!1ses!2ses!4v1722579357730!5m2!1ses!2ses"
    },
    party: {
      title: "Celebración",
      date: "Sábado 7 de Diciembre de 2024",
      location: "Grand Hotel Don Gregorio",
      locationUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6025.926590009054!2d-5.6669004235516915!3d40.960382922372055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f266ad78a0da7%3A0x73b7645be24b4cc0!2sGrand%20Hotel%20Don%20Gregorio!5e0!3m2!1ses!2ses!4v1722590838978!5m2!1ses!2ses",
    },
    locationText: "Localización"
  },
  celebration: {
    date: "7 de Diciembre de 2024",
    dateSmall: "7 Dic 2024"
  },
  rvsp: {
    title: "Confirma tu asistencia",
    confirmTitle: "Muchas gracias por participar",
    confirmDesc: "Nos vemos el 7 de diciembre en Salamanca, la vamos a liar parda!!!"
  },
  miscellaneous: {
    thankyou: 'Gracias',
    weereGettingMarried: 'Nos casamos!!!',
  }
}
